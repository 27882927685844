<template>
  <TogglePanel
    v-if="control && control.synopticComponent"
    :title="title"
    :icon="{
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down',
      before: 'fa fa-cogs'
    }"
    persistent="synoptic_control_specific_properties"
  >
    <slot></slot>
  </TogglePanel>
</template>

<script>
// import GalleryItems from "@/assets/dashboard/controls.json";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
export default {
  name: "ControlSpecificPanel",
  props: {
    control: {
      type: Object,
      required: true
    }
  },
  components: {
    TogglePanel
  },
  computed: {
    title() {
      return this.$t("specific_properties");
      // let ctrl = GalleryItems.find(
      //   (i) =>
      //     i.template.synopticComponent.componentName ==
      //     this.control.synopticComponent.componentName
      // );
      // return (
      //   (ctrl && ctrl.template.title) ||
      //   this.control.synopticComponent.componentName
      // );
    }
  }
};
</script>

<style scoped></style>
